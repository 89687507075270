<template>
    <div>
        <b-form ref="form" @submit.prevent="submit">
            <!-- id, active -->
            <b-form-group
                class="mb-0"
                label-cols="6"
                :label="record.id === 0 ? '' : $t('form.id_value', {value: record.id})"
                label-size=""
                label-class="pt-0"
            >
                <b-form-checkbox
                    id="active"
                    class="float-right"
                    switch
                    v-model="record.active"
                    name="active"
                    value="true"
                    unchecked-value="false"
                >
                    {{activeText}}
                </b-form-checkbox>
            </b-form-group>
            <b-form-row>
                <!-- post_code -->
                <b-form-group
                    id="group-post_code"
                    class="col-sm-12 col-md-6"
                    label-class="mb-0"
                    :label="$t('register.post_code')"
                    label-for="postCode"
                    description=""
                    :state="valid_postCode"
                >
                    <b-form-input
                        id="postCode"
                        v-model="record.post_code"
                        :state="valid_postCode"
                        required
                        :placeholder="$t('form.required_field')"
                    ></b-form-input>
                </b-form-group>
                <!-- city -->
                <b-form-group
                    id="group-city"
                    class="col-sm-12 col-md-6"
                    label-class="mb-0"
                    :label="$t('register.city')"
                    label-for="city"
                    description=""
                    :state="valid_city"
                >
                    <b-form-input
                        id="city"
                        :state="valid_city"
                        v-model="record.city"
                        required
                        :placeholder="$t('form.required_field')"
                    ></b-form-input>
                </b-form-group>
                <!-- state_id -->
                <b-form-group
                    id="group-continent_id"
                    class="col-sm-12 col-md-6"
                    label-class="mb-0"
                    :label="$tc('register.state', 1)"
                    label-for="state_id"
                    description=""
                    :state="valid_state"
                >
                    <b-form-select
                        id="state_id"
                        v-model="record.state_id"
                        :options="states"
                        required
                        :state="valid_state"
                    >
                    </b-form-select>
                </b-form-group>
                <!-- order -->
                <b-form-group
                    id="group-order"
                    class="col-sm-6 col-md-3"
                    label-class="mb-0"
                    :label="$t('form.order')"
                    label-for="order"
                    description=""
                >
                    <b-form-input
                        id="order"
                        type="number"
                        v-model="record.order"
                    ></b-form-input>
                </b-form-group>
            </b-form-row>
            <!--  -->
        </b-form>
    </div>
</template>

<script>
import utilsGeneral from "../../../utils/utilsGeneral";
// @group Form.REGISTER
export default {
    name: "FormPostOffice",
    components: {},
    props: {
        form: {
            valid: false,
            record: {},
        },
        data: {},
        modalBus: Object,
    },
    data() {
        return {
            valid: null,
            record: {
                id: 0,
                active: false,
                order: 0,
                state_id: null,
                post_code: '',
                city: '',
            },
            states: [],
        }
    },
    mounted() {
        this.modalBus.$on('submit', this.submit);
        // <-- set foreign data -->
        this.setStates();

        if (this.form.record != null) {
            this.$set(this, 'record', this.form.record);
        }
    },
    methods: {
        // <-- set selects data -->
        setStates() {
            let states = [
                {value: null, text: '/', disabled: false},
            ];
            let meta = false;
            let locale = {};
            this.data.states.forEach((state, index) => {
                meta = false;
                locale = state.locale[this.$i18n.locale];
                // <-- if missing locale data -->
                if (locale === undefined) {
                    meta = true;
                    locale = state.meta;
                }
                states.push(
                    {
                        value: state.id,
                        text: locale.name + (meta ? this.$t('form.meta.message', {msg: this.$t('name')}) : ''),
                        disabled: false
                    }
                );
            });
            // <--  -->
            this.$set(this, 'states', states);
        },
        // <-- form -->
        submit() {
            if (this.$refs.form === undefined) {
                return;
            }
            this.valid = this.$refs.form.checkValidity();

            this.$set(this.form, 'record', this.record);
            this.$set(this.form, 'valid', this.valid);
        }
    },
    computed: {
        activeText: {
            get() {
                return !this.record.active || this.record.active === 'false' || this.record.active === undefined
                    ? this.$t('form.inactive')
                    : this.$t('form.active');
            }
        },
        valid_postCode() {
            if (this.valid === null) {
                return null;
            }
            return this.record.post_code.length > 0 ? null : false;
        },
        valid_city() {
            if (this.valid === null) {
                return null;
            }
            return this.record.city.length > 0 ? null : false;
        },
        // <-- valid foreign data -->
        valid_state() {
            if (this.valid === null) {
                return null;
            }
            return this.record.state_id > 0 ? null : false;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
